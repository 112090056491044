import React, { useEffect, useState } from "react";
import Link from "next/link";
import "aos/dist/aos.css";
import Image from "next/image";
import styles from "../styles/Home.module.css";
import facebook from "../images/facebook.png";
import linkdin from "../images/linkedin.png";
import instagram from "../images/instagram.png";
import verify from "../images/verified.png";
import member from "../images/member.png";
import Tick from "../images/icons8-tick-144.png";
import { styled } from "@mui/material/styles";
import { Container, TextField, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

const SubButton = styled(Button)(({ theme }) => ({
  color: "var(--dark-color)",
  border: "1px solid var(--primary-color)",
  fontWeight: "600",
  fontSize: "15px",
  marginTop: "3%",
  backgroundColor: "#ffffff",
  "&:hover": {
    border: "1px solid var(--dark-color)",
    backgroundColor: "#ffffff",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "15%",
  },
}));

export default function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LfBDMwkAAAAAMpjJ5Qgh6YLotaoJtnI1DN2Ul4H";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const regex = /^[a-zA-Z0-9@._-]*$/;

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email && name) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LfBDMwkAAAAAMpjJ5Qgh6YLotaoJtnI1DN2Ul4H", {
            action: "submit",
          })
          .then((token) => {
            const data = {
              full_name: name,
              email: email,
              token_id: token,
            };

            setToken(token);

            axios
              .post("https://abodefinder.com.au/api/subscribe/", data)
              .then((res) => {
                setShowModal(true);
                setInterval(() => {
                  setShowModal(false);
                }, 3000);

                setName("");
                setEmail("");
              })
              .catch((err) => {
                console.log(err);
              });
          });
      });
    }
  };

  const handleChangeName = (event) => {
    if (regex.test(event.target.value)) {
      setName(event.target.value);
    }
  };

  const handleChangeEmail = (event) => {
    if (regex.test(event.target.value)) {
      setEmail(event.target.value);
    }
  };

  return (
    <>
      <footer>
        <Dialog open={showModal} fullWidth={true}>
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center" }}
          >
            <br></br>
            <Image
              src={Tick}
              alt="facebook"
              width={60}
              style={{ margin: "auto" }}
            />
            <br></br>
            <h3>You Have Been Successfully Subscribed.</h3>
            <br></br>
          </DialogTitle>
        </Dialog>
        {/* <div className={styles.footerCenter}>
          <div className={styles.borderContactus}>
            <h4>
              Join our 24K+ subscribers to receive Property and Finance Monthly
              Update
            </h4>
            <Link href="/" className={styles.btnSubscribe} target="_blank">
              Subscribe
            </Link>
          </div>
        </div> */}
        <div className={styles.footerColumn}>
          <h3>Still looking?</h3>
          <div className={styles.columnRow}>
            <div className={styles.column}>
              <h6>Calculators</h6>
              <div className={styles.footerColumnLine}></div>
              <Link href="/repayment_calculator/" target="_blank">
                Repayment Calculator
              </Link>
              <Link href="/equity_calculator/" target="_blank">
                Equity Calculator
              </Link>
              <Link href="/buying-chance/" target="_blank">
                Buying Chance Calculator
              </Link>
            </div>
            <div className={styles.column}>
              <h6>Suburbs Profile</h6>
              <div className={styles.footerColumnLine}></div>
              <Link href="/state-directory/" target="_blank">
                Suburbs
              </Link>
            </div>

            <div className={styles.column} id="footer-subscribe">
              <h6>Subscribers to receive Property Investment Monthly Update</h6>
              <div
                className={styles.footerColumnLine}
                id="footer-subscribe-line"
                style={{ width: "100%" }}
              ></div>
              <Container component="main" maxWidth="xs">
                <form onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    className="footer-subscribe-input"
                    value={name}
                    onChange={handleChangeName}
                    type="text"
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    className="footer-subscribe-input"
                    value={email}
                    onChange={handleChangeEmail}
                    type="email"
                  />

                  <SubButton
                    className={styles.btnSubscribe}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Subscribe
                  </SubButton>
                </form>
              </Container>
            </div>
          </div>
        </div>
        <div className="footer-type2">
          <ul>
            <li>
              <Link href="/disclaimer/" style={{ color: "white" }}>
                Disclaimer
              </Link>
            </li>
            <li>
              <Link href="/contact/" style={{ color: "white" }}>
                Be Our Partner
              </Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link href="/privacy-policy/" style={{ color: "white" }}>
                Privacy Policy
              </Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link href="/contact/" style={{ color: "white" }}>
                Contact us
              </Link>
            </li>
          </ul>

          <ul>
            <li>Follow us on:</li>
            <li>
              <Link
                href="https://www.facebook.com/abodefinderau/"
                target="_blank"
              >
                <Image src={facebook} alt="facebook" width={38} />
              </Link>
              <Link
                href="https://www.linkedin.com/company/abodefinderaustralia/"
                target="_blank"
              >
                <Image src={linkdin} alt="linkdin" width={38} />
              </Link>
              <Link
                href="https://www.instagram.com/abodefinder/"
                target="_blank"
              >
                <Image src={instagram} alt="instagram" width={38} />
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-type3">
          <ul>
            <li>
              <Link href="https://buyeragentfinder.com.au/" target="_blank">
                <Image src={verify} alt="verify" width={120} height={120} />
              </Link>
              <Link href="https://buyeragentfinder.com.au/" target="_blank">
                <Image src={member} alt="member" width={120} height={120} />
              </Link>
            </li>
          </ul>
        </div>
      </footer>
      <div className="info">
        <p>Important stuff</p>
        <p>
          {" "}
          Our mission is to change the way Australians buy their dream home by
          providing a faster and more innovative experience designed around the
          customer’s convenience
        </p>
        <p>
          The Data provided in this publication is of a general nature and
          should not be construed as specific advice or relied upon in lieu of
          appropriate professional advice. While AbodeFinder uses commercially
          reasonable efforts to ensure the data is current,AbodeFinder does not
          warrant the accuracy, currency or completeness of the data and to the
          full extent permitted by law excludes all loss or damage howsoever
          arising (including through negligence) in connection with the data.
        </p>
        <p>
          This is intended for informational purposes only and may not be
          reproduced or re-disseminated in any form and may not be used as a
          basis for or a component of any financial decisions.
        </p>
        <p>
          AbodeFinder does not warrant the accuracy, currency or completeness of
          the prediction and to the full permitted by law, AbodeFinder excludes
          all liability for any loss or damage howsoever arising in connection
          with all data in AbodeFinder.
        </p>
        <p>
          {" "}
          © <span>{new Date().getFullYear()}</span>. All rights reserved.
        </p>
      </div>
    </>
  );
}
